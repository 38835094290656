import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PingTest = () => {
  const [displayData, setDisplayData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState("");
  const [testing, setTesting] = useState(false);
  const [testUrl, setTestUrl] = useState('https://www.cloudflare.com/cdn-cgi/trace');
  const [intervalTime, setIntervalTime] = useState(1000);
  const [recentTimeouts, setRecentTimeouts] = useState([]);

  useEffect(() => {
    let interval;
    if (testing) {
      interval = setInterval(async () => {
        const timestamp = new Date().toLocaleTimeString();
        const start = Date.now();
        try {
          await axios.get(testUrl, { timeout: 1000 });
          const latency = Date.now() - start;
          const newRecord = { timestamp, latency };
          setAllData(prevData => [...prevData, newRecord]);
          setDisplayData(prevData => {
            const updatedData = [...prevData, newRecord];
            return updatedData.slice(-15);
          });
        } catch (error) {
          const newRecord = { timestamp, latency: -1 };
          setAllData(prevData => [...prevData, newRecord]);
          setDisplayData(prevData => {
            const updatedData = [...prevData, newRecord];
            return updatedData.slice(-15);
          });
          setRecentTimeouts(prev => {
            const updatedTimeouts = [...prev, timestamp];
            return updatedTimeouts.slice(-5);
          });
        }
      }, intervalTime);
    }
    return () => clearInterval(interval);
  }, [testing, testUrl, intervalTime]);

  const handleStartStop = () => {
    setTesting(!testing);
  };

  const handleNoteAdd = () => {
    const timestamp = new Date().toLocaleTimeString();
    const noteEntry = { timestamp, note: currentNote };
    setNotes([...notes, noteEntry]);
    setCurrentNote("");
  };

  const handleExport = () => {
    let csvContent = "Timestamp,Latency(ms),Note\n";
    const exportData = allData.map(ping => ({
      ...ping,
      note: notes.find(n => n.timestamp === ping.timestamp)?.note || ""
    }));
    exportData.forEach(entry => {
      csvContent += `${entry.timestamp},${entry.latency},${entry.note}\n`;
    });

    const blob = new Blob(["\uFEFF" + csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "ping_test_report.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <h1>Test</h1>
      <div>
        <label>
          Server:
          <input
            type="text"
            value={testUrl}
            onChange={e => setTestUrl(e.target.value)}
            style={{fontSize: '16px'}}
          />
        </label>
        <br/>
        <label>
          Interval (ms):
          <input
            type="number"
            value={intervalTime}
            onChange={e => setIntervalTime(Number(e.target.value))}
            style={{fontSize: '16px'}}
          />
        </label>
      </div>
      <button onClick={handleStartStop}>
        {testing ? '停止测试' : '开始测试'}
      </button>
      <div>
        <div>
          <h3>Recent timeouts</h3>
          <ul>
            {recentTimeouts.map((time, index) => (
              <li key={index}>{time}</li>
            ))}
          </ul>
        </div>
        <h3>Recent records</h3>
        <table style={{width: '100%', textAlign: 'left', borderSpacing: '0'}}>
          <thead>
          <tr>
            <th style={{padding: '1px 0', fontWeight: 'bold'}}>Time</th>
            <th style={{padding: '1px 0', fontWeight: 'bold'}}>HTTP Ping</th>
            <th style={{padding: '1px 0', fontWeight: 'bold'}}>Remarks</th>
          </tr>
          </thead>
          <tbody>
          {displayData.map((ping, index) => (
            <tr key={index}>
              <td style={{padding: '1px 0'}}>{ping.timestamp}</td>
              <td style={{padding: '1px 0'}}>{ping.latency === -1 ? 'TIME OUT' : `${ping.latency} ms`}</td>
              <td style={{padding: '1px 0'}}>{notes.find(note => note.timestamp === ping.timestamp)?.note || ""}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      <div>
        <h2>Marks</h2>
        <textarea
          value={currentNote}
          onChange={e => setCurrentNote(e.target.value)}
          placeholder="Write here..."
          style={{width: '100%', boxSizing: 'border-box'}} // 占满一行
        />
        <button onClick={handleNoteAdd} style={{marginTop: '8px'}}>Add</button>
        {/* 放在下一行 */}
        <ul>
          {notes.map((note, index) => (
            <li key={index}>
              {note.timestamp} - {note.note}
            </li>
          ))}
        </ul>
      </div>

      <button onClick={handleExport}>Export Results CSV</button>
    </div>
  );
};

export default PingTest;
